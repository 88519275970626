import React from 'react'
import HTag from '@/lib/HTag'
import Richtext from '@/lib/rt/Richtext'
import CTAList from '../CTAList'
import * as css from './TextColumn.module.css'
import clsx from 'clsx'

const TextColumn = ({ heading, _rawDescription, ctas, _rawRichtext, columns }) => (
	<section className="section">
		<header className="text-center richtext">
			<HTag className="h0" {...heading} />
			<Richtext raw={_rawDescription} />
			<CTAList className="justify-center" ctas={ctas} />
		</header>

		<Richtext
			className={clsx(css.content, 'mt-12 richtext gap-x-12')}
			style={{ '--col': columns }}
			raw={_rawRichtext}
		/>
	</section>
)

export default TextColumn

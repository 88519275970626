import React, { useContext } from 'react'
import getAccent from '@/utils/get-accent'
import { Context, Provider, VideoModal } from '@/lib/Modal'
import HTag from '@/lib/HTag'
import Richtext from '@/lib/rt/Richtext'
import CTAList from '@/lib/CTAList'
import Img from '@/lib/Img'
import { VideoTrigger } from '@/lib/Video'
import Iframe from '../embed/Iframe'
import clsx from 'clsx'

const App = ({ accent, heading, _rawRichtext, ctas, asset, assetOnRight, blockId }) => {
	const { $modal, set$modal } = useContext(Context)

	return <>
		<section id={blockId} className={getAccent(accent)}>
			<div className="section grid md:grid-cols-2 gap-x-12 gap-y-4 items-center">
				<div className={clsx(assetOnRight && 'md:order-1')}>
					{renderAsset(asset[0], {
						onClick: () => set$modal(asset[0])
					})}
				</div>

				<div className="richtext">
					<HTag className="h0 <md:text-center" {...heading} />
					<Richtext raw={_rawRichtext} />
					<CTAList ctas={ctas} />
				</div>
			</div>
		</section>

		<VideoModal video={$modal} />
	</>
}

export function renderAsset(asset, props) {
	switch(asset?._type) {
		case 'image': return <Img image={asset} />
		case 'video': return <VideoTrigger {...asset} {...props} />
		case 'iframe': return <Iframe iframe={asset} />
		default: return null
	}
}

const HeroSideBySide = props => <Provider children={<App {...props} />} />

export default HeroSideBySide

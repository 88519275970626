import React, { useContext, useEffect } from 'react'
import clsx from 'clsx'
import * as css from './Modal.module.css'
import { Context } from './Context'

const Modal = ({ open, onClose = () => {}, className, children }) => {
	const { set$modal } = useContext(Context)

	if (!open) return null

	function close() {
		onClose()
		set$modal(false)
	}

	useEffect(() => {
		if (typeof window === 'undefined') return

		// Escape to close
		const onKeyup = ({ key }) => key === 'Escape' && close()
		window.addEventListener('keyup', onKeyup)

		return () => {
			window.removeEventListener('keyup', onKeyup)
		}
	}, [close])

	return (
		<aside className={clsx(css.root, 'fixed inset-0 z-10 grid bg-black/50 text-white backdrop-blur')}>
			<div className="absolute inset-0 cursor-pointer" onClick={close} />

			<button
				className="absolute z-[1] top-0 right-0 p-4 text-3xl mix-blend-difference"
				onClick={close}
				title="Close modal"
			>
				&times;
			</button>

			<div className={clsx('relative m-auto w-full max-h-screen overflow-y-auto overscroll-contain', className)}>
				{children}
			</div>
		</aside>
	)
}

export default Modal

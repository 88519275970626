import React, { useContext } from 'react'
import { Context, Provider, VideoModal } from '@/lib/Modal'
import HTag from '@/lib/HTag'
import Richtext from '@/lib/rt/Richtext'
import { renderAsset } from './HeroSideBySide'
import CTAList from '@/lib/CTAList'
import clsx from 'clsx'

const App = ({ heading, _rawRichtext, ctas, asset, assetOnRight, className, children }) => {
	const { $modal, set$modal } = useContext(Context)

	return <>
		<article className={clsx('grid md:grid-cols-2 gap-x-12 gap-y-4 items-center mt-12', className)}>
			<div className={clsx(assetOnRight && 'md:order-1')}>
				{renderAsset(asset[0], {
					onClick: () => set$modal(asset[0])
				})}
			</div>

			<div className="richtext">
				<HTag className="h0 <md:text-center" {...heading} />
				<Richtext raw={_rawRichtext} />
				<CTAList ctas={ctas} />

				{children}
			</div>
		</article>

		<VideoModal video={$modal} />
	</>
}

const HeroItem = props => <Provider children={<App {...props} />} />

export default HeroItem

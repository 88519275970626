import React from 'react'
import getAccent from '@/utils/get-accent'
import HeroItem from './HeroItem'
import { IconItem } from '../IconList'

const HeroIconList = ({ accent, hero, items }) => (
	<section className={getAccent(accent)}>
		<div className="section">
			<HeroItem {...hero} />

			<div className="grid md:grid-cols-3 gap-8 mt-12">
				{items?.map((item, key) => <IconItem {...item} key={key} />)}
			</div>
		</div>
	</section>
)

export default HeroIconList

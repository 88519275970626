import React from 'react'
import Modal from './Modal'
import { Video } from '@/lib/Video'

const VideoModal = ({ video }) => !!video && (
	<Modal open={!!video} className="max-w-[800px] p-4">
		<Video {...video} />
	</Modal>
)

export default VideoModal

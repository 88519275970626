import React, { useContext } from 'react'
import { Context } from '@/lib/Modal/Context'
import { VideoTrigger } from '@/lib/Video'

const Item = ({ quote, author, author_title, video }) => {
	const { set$modal } = useContext(Context)

	return (
		<article className="richtext">
			<VideoTrigger {...video} onClick={() => set$modal(video)} />

			<p className="font-serif text-[1.2em]">{quote}</p>
			<p className="technical">
				<strong>{author}</strong>
				{author_title && <> | {author_title}</>}
			</p>
		</article>
	)
}

export default Item

import React from 'react'
import Employee from './Employee'
import * as css from './Team.module.css'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import clsx from 'clsx'

const Team = ({ name, members }) => (members?.length > 0) && (
	<article className={clsx(css.team, 'text-center py-12')}>
		<h2 className="h2">{name}</h2>

		<Swiper
			className={clsx(css.swiper, 'mt-12 !px-4 <md:!full-width')}
			modules={[Navigation]}
			spaceBetween={30}
			navigation
			breakpoints={{
				428: { slidesPerView: 2 },
				768: { slidesPerView: 5 },
			}}
		>
			{members?.map((employee, key) => (
				<SwiperSlide>
					<Employee {...employee} key={key} />
				</SwiperSlide>
			))}
		</Swiper>
	</article>
)

export default Team

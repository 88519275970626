import React, { useContext } from 'react'
import { Context } from '@/lib/Modal'
import Img from '@/lib/Img'
import HTag from '@/lib/HTag'
import clsx from 'clsx'

const Employee = ({ name, title, image, _rawBio, links, tag = 'h3' }) => {
	const { set$modal } = useContext(Context)

	const hasModal = !!_rawBio || !!links?.length

	function onClick() {
		if (!hasModal) return null

		set$modal({ name, title, image, _rawBio, links })
	}

	return (
		<div className={clsx(hasModal && 'cursor-pointer')} onClick={onClick}>
			<figure className="max-w-[120px] mx-auto mb-2 rounded-full overflow-hidden">
				{image
					? <Img
						as="div"
						imgClassName="rounded-full overflow-hidden"
						image={image}
						alt={`${name}, ${title}`}
					/>
					: <div className="aspect-square bg-white/10" />
				}
			</figure>
			<HTag className="font-bold text-xl" text={name} tag={tag} />
			{title && <p>{title}</p>}
		</div>
	)
}

export default Employee

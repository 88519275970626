import React, { useContext } from 'react'
import { Context, Provider } from '../Context'
import HTag from '@/lib/HTag'
import Tabs from './Tabs'
import Richtext from '@/lib/rt/Richtext'
import CTA from '@/lib/CTA'

const components = {
	types: {
		cta: ({ value }) => <p><CTA {...value} /></p>
	}
}

const App = ({ tabs }) => {
	const { $active } = useContext(Context)

	const { heading } = tabs[$active]

	return (
		<section className="section">
			{!!heading?.text && (
				<header className="text-center mb-12">
					<HTag className="h0" {...heading} />
				</header>
			)}

			<Tabs tabs={tabs} />

			{tabs?.map(({ _rawRichtext }, key) => (
				<article className="mt-8 richtext anim-appear" hidden={$active !== key} key={key}>
					<Richtext raw={_rawRichtext} components={components} />
				</article>
			))}
		</section>
	)
}

const TabbedRichtext = props => <Provider children={<App {...props} />} />

export default TabbedRichtext

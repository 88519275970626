import React from 'react'
import CTA from '@/lib/CTA'
import clsx from 'clsx'

const CTAList = ({ ctas, className }) => !!ctas?.length && (
	<p className={clsx('flex flex-wrap items-center gap-[1em] <md:justify-center', className)}>
		{ctas?.map((cta, key) => (
			<CTA {...cta} key={key} />
		))}
	</p>
)

export default CTAList

import React from 'react'
import HTag from '@/lib/HTag'

const Iframe = ({ heading, iframe }) => (
	<section className="section">
		<HTag className="h1 mb-4" {...heading} />
		<iframe className="w-full" {...iframe} />
	</section>
)

export default Iframe

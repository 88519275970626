import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import HTag from '@/lib/HTag'
import { HiOutlineDownload, HiOutlineChevronDown } from 'react-icons/hi'
import * as css from './ResourceList.module.css'
import clsx from 'clsx'

const ResourceList = ({ heading }) => {
	const { sanityResourceListRef } = useStaticQuery(query)

	return (
		<section className="section grid md:grid-cols-2 gap-8 items-start">
			<HTag className="h0 md:sticky-below-header" {...heading} />

			<ul>
				{sanityResourceListRef?.resources?.map(({ _type, ...props }, key) => {
					switch (_type) {
						case 'resource': return <Resource {...props} className="border-b border-current" key={key} />
						case 'resource-group': return <ResourceGroup {...props} key={key} />
						default: return null
					}
				})}
			</ul>
		</section>
	)
}

const Resource = ({ title, file, className }) => (
	<li className={className}>
		<a className={css.item} href={file?.asset.url} target="_blank">
			{title}
			<HiOutlineDownload/>
		</a>
	</li>
)

const ResourceGroup = ({ title, resources }) => !!resources?.length && (
	<li className="border-b border-current">
		<details className={clsx(css.details, 'group')}>
			<summary className={clsx(css.item, 'group-open:font-bold')}>
				{title}
				<HiOutlineChevronDown/>
			</summary>
			<ul className="pb-4 px-4">
				{resources?.map((resource, key) => (
					<Resource {...resource} key={key} />
				))}
			</ul>
		</details>
	</li>
)

export default ResourceList

const query = graphql`query ResourceList {
	sanityResourceListRef {
		resources {
			...resource
			...resourceGroup
		}
	}
}`

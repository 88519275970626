import React, { createContext, useState } from 'react'

export const Context = createContext({})

export const Provider = ({ children }) => {
	const [$modal, set$modal] = useState(false)

	return <Context.Provider
		value={{
			$modal, set$modal,
		}}
		children={children}
	/>
}

import React from 'react'
import HTag from '@/lib/HTag'
import Richtext from '@/lib/rt/Richtext'
import CTAList from '@/lib/CTAList'
import HeroItem from './HeroItem'
import IconList from '../IconList'

const HeroStack = ({ heading, _rawRichtext, ctas, items }) => (
	<section className="section">
		<header className="max-w-2xl mx-auto text-center richtext">
			<HTag className="h0" {...heading} />
			<Richtext raw={_rawRichtext} />
			<CTAList className="justify-center" ctas={ctas} />
		</header>

		{items?.map((item, key) => <Stack {...item} key={key} />)}
	</section>
)

const Stack = ({ _type, ...props }) => {
	switch(_type) {
		case 'hero.side-by-side': return <HeroItem {...props} />
		case 'icon-list': return <IconList {...props} />
		default: return null
	}
}

export default HeroStack

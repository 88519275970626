import * as React from 'react'
import HTagAnimated from '@/lib/HTagAnimated'
import Richtext from '@/lib/rt/Richtext'
import CTAList from '@/lib/CTAList'
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'

const HeroMain = ({ heading, _rawRichtext, ctas, image, imageMobile }) => {
	const images = !!imageMobile?.asset
		? withArtDirection(getImage(image.asset), [
			{ media: '(max-width: 768px)', image: getImage(imageMobile.asset) },
		])
		: getImage(image.asset)

	return (
		<section className="hero-main section grid md:grid-cols-2 lg:grid-cols-2 items-center gap-8">
			<div className="richtext md:px-8">
				<HTagAnimated heading={heading} />
				<Richtext raw={_rawRichtext} />
				<CTAList ctas={ctas} />
			</div>

			{!!image?.asset && (
				<figure className="mx-auto max-w-[450px]">
					<GatsbyImage
						image={images}
						alt={heading.text}
					/>
				</figure>
			)}
		</section>
	)
}

export default HeroMain

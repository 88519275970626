import React from 'react'

import ArticleList from './ArticleList'
import EmbedIframe from './embed/Iframe'
import FlagList from './FlagList'
import HeadingBasic from './HeadingBasic'
import HeroBgImg from './hero/HeroBgImg'
import HeroCarousel from './hero/HeroCarousel'
import HeroCentered from './hero/HeroCentered'
import HeroIconList from './hero/HeroIconList'
import HeroMain from './hero/HeroMain'
import HeroSideBySide from './hero/HeroSideBySide'
import HeroStack from './hero/HeroStack'
import IconList from './IconList'
import ResourceList from './ResourceList'
import TabbedRichtext from './tabbed/richtext'
import TeamList from './employee/TeamList'
import Testimonial from './Testimonial'
import TestimonialCarousel from './TestimonialCarousel'
import TextColumn from './TextColumn'

const Block = ({ _type, _key, ...rest }) => {
	const props = {
		...rest,
		key: _key,
		blockId: `block-${ _key }`,
	}

	switch(_type) {
		case 'article-list': return <ArticleList {...props} />
		case 'embed.iframe': return <EmbedIframe {...props} />
		case 'flag-list': return <FlagList {...props} />
		case 'heading.basic': return <HeadingBasic {...props} />
		case 'hero-carousel': return <HeroCarousel {...props} />
		case 'hero-stack': return <HeroStack {...props} />
		case 'hero.bg-img': return <HeroBgImg {...props} />
		case 'hero.centered': return <HeroCentered {...props} />
		case 'hero.icon-list': return <HeroIconList {...props} />
		case 'hero.main': return <HeroMain {...props} />
		case 'hero.side-by-side': return <HeroSideBySide {...props} />
		case 'icon-list': return <IconList {...props} />
		case 'resource-list': return <ResourceList {...props} />
		case 'tabbed-richtext': return <TabbedRichtext {...props} />
		case 'team-list': return <TeamList {...props} />
		case 'testimonial': return <Testimonial {...props} />
		case 'testimonial-carousel': return <TestimonialCarousel {...props} />
		case 'text-column': return <TextColumn {...props} />
		default: return <div data-type={_type} key={_key} />
	}
}

const Blocks = ({ blocks, ...props }) => blocks?.map(block => !!block && (
	<Block {...block} {...props} key={block._key} />
))

export default Blocks

import React, { useState } from 'react'
import getAccent from '@/utils/get-accent'
import HeroItem from './HeroItem'
import { Nav } from '../../Carousel'
import clsx from 'clsx'

const HeroCarousel = ({ accent, items }) => {
	const [$active, set$active] = useState(0)

	return (
		<section className={getAccent(accent)}>
			<div className="section">
				{items?.map((item, key) => (
					<HeroItem className={clsx($active !== key && 'hidden', 'anim-appear')} {...item} key={key}>
						<Nav
							invert
							state={[$active, set$active]}
							size={items.length}
						/>
					</HeroItem>
				))}
			</div>
		</section>
	)
}

export default HeroCarousel

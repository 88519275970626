import React from 'react'
import HTag from '@/lib/HTag'
import Richtext from '../rt/Richtext'

const HeadingBasic = ({ textAlign, heading, _rawRichtext }) => (
	<section className="section" style={{ textAlign }}>
		<HTag className="h0" {...heading} />
		{_rawRichtext && (
			<Richtext className="richtext mt-4" raw={_rawRichtext} />
		)}
	</section>
)

export default HeadingBasic

import React from 'react'
import Layout from '@/layout/Layout'
import Blocks from '@/lib/blocks'
import MetaInfo from '@/lib/MetaInfo'
import { graphql } from 'gatsby'

const HomePg = ({ data: { pg } }) => (
	<Layout footerCta={pg.footerCta}>
		<Blocks blocks={pg.blocks} />
	</Layout>
)

export default HomePg

export const Head = MetaInfo

export const query = graphql`query Page ($id: String) {
	pg: sanityPage(id: {eq: $id}) {
		title
		blocks {
			...articleList
			...embedIframe
			...flagList
			...headingBasic
			...heroBgImg
			...heroCarousel
			...heroCentered
			...heroIconList
			...heroMain
			...heroSideBySide
			...heroStack
			...iconList
			...resourceList
			...tabbedRichtext
			...teamList
			...testimonial
			...testimonialCarousel
			...textColumn
		}
		footerCta
		metaInfo { ...metaInfo }
	}
}`

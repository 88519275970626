import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import getAccent from '@/utils/get-accent'
import HTag from '@/lib/HTag'
import clsx from 'clsx'
import Carousel, { Nav } from '@/lib/Carousel'
import Preview from '@/lib/article/Preview'
import PreviewStyled from '../article/PreviewStyled'

const ArticleList = ({ heading, categories, articles, styled, accent }) => {
	const { allSanityArticle } = useStaticQuery(query)

	const category_slugs = categories?.map(c => c?.slug?.current)

	const items = articles?.length
		? articles
		: allSanityArticle.nodes.filter(a => !category_slugs?.length || category_slugs.includes(a.category?.slug?.current))

	const [$active, set$active] = useState(0)

	return (
		<section className={getAccent(accent)}>
			<div className="section">
				<header className={clsx('mb-8', styled ? 'grid md:grid-cols-[1fr,auto] gap-x-8 gap-y-4' : 'text-center')}>
					<HTag className="h0" {...heading} />

					{styled && <Nav className="md:hidden" state={[$active, set$active]} size={items.slice(0, 2).length} />}
				</header>

				{styled
					? <StyledList items={items.slice(0, 2)} state={[$active, set$active]} />
					: <Carousel className="grid md:grid-cols-3 gap-8" items={items.slice(0, 3)} component={Preview} />
				}
			</div>
		</section>
	)
}

const StyledList = ({ items, state }) => {
	const [$active, set$active] = state

	return (
		<ul className="grid md:grid-cols-2 gap-12 max-w-screen-lg">
			{items?.map((item, key) => (
				<li className={clsx($active !== key && '<md:hidden', 'anim-appear')} key={key}>
					<PreviewStyled {...item} key={key} />
				</li>
			))}
		</ul>
	)
}

export default ArticleList

const query = graphql`query ArticleList {
	allSanityArticle(sort: { fields: date, order: DESC }) {
		nodes {
			...articlePreview
		}
	}
}`

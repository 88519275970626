import React from 'react'
import Carousel from '../Carousel'
import Img from '../Img'
import CTA from '../CTA'
import clsx from 'clsx'
import * as css from './IconList.module.css'

const IconList = ({ items, textAlign, style, className }) => (
	<section className={clsx(css.root, 'section <md:!text-left', className)} style={{ textAlign }}>
		{render(style, items)}
	</section>
)

function render(style, items) {
	switch(style) {
		case 'stacked': return (
			<div className="grid md:grid-cols-3 gap-8 gap-y-12">
				{items?.map((item, key) => <IconItem {...item} key={key} />)}
			</div>
		)

		default: return (
			<div className="<md:max-w-xs mx-auto">
				<Carousel className="md:grid grid-cols-3 gap-8" items={items} component={IconItem} />
			</div>
		)
	}
}

export const IconItem = ({ icon, title, text, cta }) => (
	<div className="richtext mx-auto">
		<dt>
			<Img className="mb-2" image={icon} />
			<h2 className="h2">{title}</h2>
		</dt>
		<dd className="empty:hidden richtext">
			{text && <p>{text}</p>}
			{cta?.link && <p><CTA {...cta} /></p>}
		</dd>
	</div>
)

export default IconList

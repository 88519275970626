import React from 'react'
import HTag from '@/lib/HTag'
import { useInView } from 'react-intersection-observer'
import clsx from 'clsx'

const HTagAnimated = ({ heading }) => {
	const { ref, inView } = useInView({
		rootMargin: '-120px 0px 0px 0px',
		threshold: 1,
	})

	return (
		<div ref={ref}>
			<HTag
				className={clsx('h0 inline anim-underline', inView && 'no-hover')}
				{...heading}
			/>
		</div>
	)
}

export default HTagAnimated

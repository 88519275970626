import React from 'react'
import HTag from '@/lib/HTag'
import Richtext from '@/lib/rt/Richtext'
import Img from '@/lib/Img'
import IconList from '../IconList'
import clsx from 'clsx'

const HeroBgImg = ({ heading, _rawRichtext, image, content }) => {
	const hasContent = !!content?.length

	return <>
		<section className={clsx('relative', hasContent && 'pb-28')}>
			<div className="absolute inset-0 pointer-events-none">
				<Img className="!absolute inset-0" image={image} />
				<div className="absolute inset-0 bg-gradient-to-r from-ink to-transparent <md:to-ink/50" />
			</div>

			<header className="section relative text-white">
				<div className="max-w-[600px] richtext">
					<HTag className="h0" {...heading} />
					<Richtext raw={_rawRichtext} />
				</div>
			</header>
		</section>

		{hasContent && (
			<section className="relative -mt-24 mb-12 px-4">
				<div className="_section bg-white shadow-xl">
					{content?.map((data, key) => (
						<Content {...data} key={key} />
					))}
				</div>
			</section>
		)}
	</>
}

const Content = ({ _type, ...props }) => {
	switch(_type) {
		case 'icon-list': return <IconList className="md:!px-12" {...props} />
		default: null
	}
}

export default HeroBgImg

import React from 'react'
import getAccent from '@/utils/get-accent'
import HTagAnimated from '@/lib/HTagAnimated'
import Richtext from '@/lib/rt/Richtext'
import CTAList from '@/lib/CTAList'

const HeroCentered = ({ accent, heading, _rawRichtext, ctas }) => (
	<section className={getAccent(accent)}>
		<div className="section !max-w-[730px] text-center richtext">
			<HTagAnimated heading={heading} />
			<Richtext raw={_rawRichtext} />
			<CTAList ctas={ctas} className="justify-center" />
		</div>
	</section>
)

export default HeroCentered

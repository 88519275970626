import React, { useContext } from 'react'
import { Context, Provider, VideoModal } from '@/lib/Modal'
import getAccent from '@/utils/get-accent'
import HTag from '@/lib/HTag'
import Richtext from '@/lib/rt/Richtext'
import CTAList from '@/lib/CTAList'
import Carousel from '@/lib/Carousel'
import Item from './Item'

const App = ({ accent, heading, _rawRichtext, ctas, items }) => {
	const { $modal } = useContext(Context)

	return <>
		<section className={getAccent(accent)}>
			<div className="section">
				<header className="max-w-2xl mx-auto text-center richtext">
					<HTag className="h0" {...heading} />
					<Richtext raw={_rawRichtext} />
					<CTAList className="justify-center" ctas={ctas} />
				</header>

				<Carousel
					className="md:flex items-center gap-4 mt-12"
					items={items}
					initialActive={1}
					component={Item}
					itemClassName="grow basis-[300px]"
					activeClassName="md:basis-[400px] md:text-[1.2rem]"
				/>
			</div>
		</section>

		<VideoModal video={$modal} />
	</>
}

const TestimonialCarousel = props => <Provider children={<App {...props} />} />

export default TestimonialCarousel

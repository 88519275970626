import { Link } from 'gatsby'
import React from 'react'
import Img from '../Img'
import { GoChevronRight } from 'react-icons/go'
import resolveUrl from '@/utils/resolve-url'
import DateCategory from './DateCategory'
import clsx from 'clsx'
import * as css from './PreviewStyled.module.css'

const Styled = ({ title, date, category, featuredImage, metaInfo, tag: Title = 'h3' }) => {
	const slug = metaInfo?.slug?.current

	if (!slug) return null

	return (
		<article>
			<Link className={clsx(css.link, 'group grid')} to={resolveUrl(date, slug)}>
				<div className={clsx(css.img, 'transition-all group-hover:brightness-110')}>
					{featuredImage
						? <Img image={featuredImage} alt={title} />
						: <div className="aspect-[1.5] bg-chestnut/20" />
					}
				</div>

				<div className={clsx(css.txt, 'relative grid gap-4 p-6 bg-white/90')}>
					<Title className="font-bold line-clamp">{title}</Title>

					<DateCategory date={date} category={category} />

					{!!metaInfo.description && (
						<p className="line-clamp text-sm" style={{ '--lines': 4 }}>
							{metaInfo.description}
						</p>
					)}

					<p className="with-icon justify-start text-chestnut technical font-bold text-sm link-hover">
						Read more <GoChevronRight />
					</p>
				</div>
			</Link>
		</article>
	)
}

export default Styled

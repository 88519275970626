import React from 'react'
import { RiDoubleQuotesL } from 'react-icons/ri'

const Testimonial = ({ quote, author }) => (
	<section className="<md:mx-4 <md:my-8">
		<div className="bg-green-sheen text-white">
			<figure className="section richtext">
				<RiDoubleQuotesL className="text-5xl" />
				<blockquote>
					<p className="h1 <md:h2">{quote}</p>
				</blockquote>
				<figcaption className="technical">— {author}</figcaption>
			</figure>
		</div>
	</section>
)

export default Testimonial

import React, { useContext } from 'react'
import { Context } from '../Context'
import clsx from 'clsx'
import * as css from './Tabs.module.css'

const Tabs = ({ tabs }) => {
	const { $active, set$active } = useContext(Context)

	return (
		<nav className="sticky-below-header z-[1] bg-white flex overflow-x-auto snap <md:full-width <md:px-4">
			{tabs?.map((tab, key) => (
				<button
					className={clsx(css.tab, $active === key && css.active)}
					onClick={() => set$active(key)}
					key={key}
				>
					{tab.label}
				</button>
			))}
		</nav>
	)
}

export default Tabs

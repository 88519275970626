import React from 'react'
import { Provider } from '@/lib/Modal'
import getAccent from '@/utils/get-accent'
import HTag from '@/lib/HTag'
import Richtext from '@/lib/rt/Richtext'
import Team from './Team'
import EmployeeModal from './EmployeeModal'

const App = ({ accent, heading, _rawRichtext, teams }) => <>
	<section className={getAccent(accent)}>
		<div className="section">
			<header className="max-w-2xl mx-auto text-center richtext">
				<HTag className="h0" {...heading} />
				<Richtext raw={_rawRichtext} />
			</header>

			{teams?.map((team, key) => (
				<Team {...team} key={key} />
			))}
		</div>
	</section>

	<EmployeeModal />
</>

const TeamList = props => <Provider children={<App {...props} />} />

export default TeamList

import React from 'react'
import HTag from '@/lib/HTag'
import Richtext from '@/lib/rt/Richtext'
import Img from '../Img'

const FlagList = ({ heading, _rawRichtext, items }) => (
	<section className="section">
		<header className="text-center richtext">
			<HTag className="h0" {...heading} />
			<Richtext raw={_rawRichtext} />
		</header>

		<div className="grid gap-16 mt-12">
			{items?.map((item, key) => (
				<Item {...item} key={key} />
			))}
		</div>
	</section>
)

const Item = ({ title, _rawRichtext, image }) => (
	<article className="grid md:grid-cols-[auto,1fr] items-center gap-y-4 gap-x-8">
		<figure>
			<Img as="div" className="max-w-[280px]" image={image} />
		</figure>

		<div className="richtext">
			<h3 className="h2">{title}</h3>
			<Richtext raw={_rawRichtext} />
		</div>
	</article>
)

export default FlagList

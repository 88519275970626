import React, { useContext } from 'react'
import { Context, Modal } from '@/lib/Modal'
import Img from '@/lib/Img'
import Richtext from '@/lib/rt/Richtext'
import { FaLinkedinIn } from 'react-icons/fa'
import { HiOutlineLink } from 'react-icons/hi'

const EmployeeModal = () => {
	const { $modal } = useContext(Context)
	const { name, title, image, _rawBio, links } = $modal

	return (
		<Modal open={$modal} className="max-w-[800px] grid md:grid-cols-[auto,1fr] items-start gap-8 bg-white text-ink p-8 <md:px-4">
			<div className="text-center">
				<Img className="max-w-[160px] rounded-full overflow-hidden" image={image} />

				{!!links?.length && (
					<ul className="flex justify-center gap-4 mt-4 text-2xl">
						{links?.map((link, key) => (
							<li key={key}>
								<a href={link} target="_blank">
									{
										link.includes('linkedin') ? <FaLinkedinIn />
										: <HiOutlineLink/>
									}
								</a>
							</li>
						))}
					</ul>
				)}
			</div>

			<div className="richtext">
				<div>
					<div className="h2">{name}</div>
					{title && <p>{title}</p>}
				</div>

				<Richtext raw={_rawBio} />
			</div>
		</Modal>
	)
}

export default EmployeeModal

import React, { createContext, useState } from 'react'

export const Context = createContext({})

export const Provider = ({ children }) => {
	const [$active, set$active] = useState(0)

	return <Context.Provider
		value={{
			$active, set$active,
		}}
		children={children}
	/>
}
